import { useState } from "react";
import ApiService from '../../services/api.service';
import HelperService from '../../services/helper.service';
import { Input } from "../../components/forms/Components";
import { useForm } from "react-hook-form";

const Einloesen = () => {



    const [noCodeFound, setNoCodeFound] = useState(false);
    const [codeSelection, setCodeSelection] = useState();
    const [selectedCode, setSelectedCode] = useState();
    const [codeData, setCodeData] = useState();

    const [codeSuccessInfo, setCodeSuccessInfo] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const autocompleteCode = (value) => {
        if (value.length > 2) {
            const API_URL = global.backendconfig.expressserver.url + '/codeautocomplete';
            const API_DATA = {
                searchTerm: value
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (response.list?.length) {
                    setCodeSelection(response.list);
                    setNoCodeFound(false);
                }
                else {
                    setCodeSelection();
                    setNoCodeFound(true);
                }
            })
        }
        else {
            setCodeSelection();
        }
    }


    const loadCodeData = (code) => {
        const API_URL = global.backendconfig.expressserver.url + '/getcodedata';
        const API_DATA = {
            code: code
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            setSelectedCode(code);
            setCodeData(response.result);
        })
    }

    const resetStates = () => {
        setCodeSelection();
        setSelectedCode();
        setCodeData();
        setCodeSuccessInfo(false);
        setValue('amount', '');
    }


    const onSubmit = data => {

        setSubmitted(true);

        if (codeData.appointment) {
            data.amount = codeData.balanceAmount;
        }
        data.code = selectedCode;

        const API_URL = global.backendconfig.expressserver.url + '/redeemcode';
        const API_DATA = data;
        ApiService.getData(API_URL, API_DATA).then((response) => {
            setCodeData(response.result);
            setCodeSuccessInfo(true);
            setSubmitted(false);
            setValue('amount', '');
        })
    }


    let selectionButtons = [];
    if (codeSelection?.length) {
        codeSelection.forEach(code => {
            selectionButtons.push(
                <button className="mr" key={code} type="button" onClick={() => loadCodeData(code)}>{code}</button>
            )
        });
    }


    let cont;
    if (selectedCode && codeData) {
        cont =
            <>
                <table className="table data">
                    <tbody>
                        <tr><td>Code:</td><td>{selectedCode}</td></tr>
                        <tr><td>Produkt:</td><td>{codeData.productName}</td></tr>
                        <tr><td>Käufer:</td><td>{codeData.customer}</td></tr>
                        <tr><td>Anfangsstand:</td><td>{HelperService.formatPrice(codeData.amount)}</td></tr>
                        <tr><td>Aktueller Stand:</td><td>{HelperService.formatPrice(codeData.balanceAmount)}</td></tr>
                        <tr><td>Terminbasiert:</td><td>{codeData.appointment ? 'ja' : 'nein'}</td></tr>
                        <tr><td>Teileinlösbar:</td><td>{codeData.fragmentable ? 'ja' : 'nein'}</td></tr>
                        <tr><td>Vollständig eingelöst:</td><td>{codeData.redeemed ? 'ja' : 'nein'}</td></tr>
                        <tr><td>Ablaufdatum:</td><td>{codeData.expiryDate ? (codeData.expired ? <span style={{ 'color': 'red' }}>{HelperService.dateToStringWithoutTime(codeData.expiryDate)}</span> : HelperService.dateToStringWithoutTime(codeData.expiryDate)) : 'unbegrenzt gültig'}</td></tr>
                    </tbody>
                </table>

                {codeData.redeemed
                    ?
                    <form className="form-add">
                        <div className="cpart">
                            <p className="error">Dieser Code wurde bereits vollständig eingelöst.</p>
                            <div className="submit_container">
                                <div className="formfieldholder">
                                    <button type="button" className="back cancel nml" disabled={submitted} onClick={() => resetStates()}>zurück</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    :
                    <form onSubmit={handleSubmit(onSubmit)} className="form-add" onKeyDown={(e) => { HelperService.handleFormKeyDown(e) }}>
                        {codeSuccessInfo &&
                            <p className="success">Der Betrag wurde erfolgreich eingelöst!</p>
                        }
                        <div className="cpart">
                            <br />
                            <h2>Code einlösen</h2>
                            <Input register={register} type="number" valasnum step="0.01" min="0" max={codeData.balanceAmount} name="amount" label="Einlösebetrag" size="third-left" setValue={setValue} data={!codeData.fragmentable || codeData.appointment || codeData.balanceAmount === 0 ? codeData.balanceAmount : ''} required currency errors={errors} readonly={!codeData.fragmentable || codeData.appointment || codeData.balanceAmount === 0 ? true : false}></Input>
                        </div>

                        <div className="cpart">
                            <div className="submit_container">
                                <div className="formfieldholder">
                                    <button type="submit" className={"submit" + (submitted ? ' load' : '')} disabled={submitted}>Code einlösen</button>
                                    <button type="button" className="back cancel" disabled={submitted} onClick={() => resetStates()}>zurück</button>
                                </div>
                            </div>
                        </div>
                    </form >
                }
            </>
            ;
    }
    else {
        cont =
            <>
                <p>Bitte geben Sie mindestens die ersten 3 Stellen des Codes ein.</p>
                <form className="form-add">
                    <div className="third-left">
                        <input
                            type="text"
                            placeholder="Gutscheincode"
                            onChange={(e) => autocompleteCode(e.target.value)}
                        />
                    </div>
                    {(selectionButtons.length > 0) &&
                        <div className="cpart">
                            <br />
                            <p>Bitte wählen Sie einen Code aus:</p>
                            {selectionButtons}
                        </div>
                    }

                    {noCodeFound &&
                        <div className="cpart">
                            <br />
                            <p className="error">Es wurde kein passender Code gefunden.</p>
                        </div>
                    }



                </form>
            </>;
    }



    return (
        <>
            <div className='content'>
                <span className="sectiontitle">Code einlösen</span>
                {cont}
            </div>
        </>
    )
}

export default Einloesen