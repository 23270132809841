import { DataGrid, deDE } from '@mui/x-data-grid';

const LastOrders = ({ data }) => {

    const headCells = [
        {
            field: 'orderStamp',
            flex: 1,
            minWidth: 160,
            headerName: 'Zeit',
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            valueFormatter: params => new Date(params?.value).toLocaleString('de-DE', { dateStyle: 'medium', timeStyle: 'medium' })
        },
        {
            field: 'orderNumber',
            flex: 1,
            minWidth: 100,
            headerName: 'Bestellnr.',
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'invoiceNumber',
            flex: 1,
            minWidth: 120,
            headerName: 'Rechnungsnr.',
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'customer',
            flex: 1,
            minWidth: 200,
            headerName: 'Kundenname',
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'email',
            flex: 1,
            minWidth: 280,
            headerName: 'E-Mail',
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'paymentGroup',
            flex: 1,
            minWidth: 100,
            headerName: 'Anbieter',
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'paymentProvider',
            flex: 1,
            minWidth: 150,
            headerName: 'Bezahlmittel',
            disableColumnMenu: true,
            sortable: false,
        },
        {
            field: 'state',
            flex: 1,
            minWidth: 120,
            headerName: 'Status',
            disableColumnMenu: true,
            sortable: false,
        }
    ];

    let table;
    if (data.length) {
        table =
            <>
                <div className="sectiontitle">Letzte Bestellungen</div>
                <div className="listcontainer">
                    <div className='table nomargin'>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={data}
                            columns={headCells}
                            disableRowSelectionOnClick={true}
                            columnHeaderHeight={38}
                            rowHeight={42}
                            hideFooter
                            disableColumnSelector
                            disableDensitySelector
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'orderStamp', sort: 'desc' }],
                                }
                            }}
                        />
                    </div>
                </div>
            </>
    }

    return table;
}

export default LastOrders;
