import { ResponsiveLine } from '@nivo/line'
import HelperService from '../../services/helper.service';

const Chart = ({ data }) => {

    let newData = [];

    data.forEach(entry => {
        newData.push({
            x: entry.label,
            y: entry.value
        });
    });

    let chartData = [
        {
            id: 'Einnahmen',
            data: newData
        }
    ]

    let cont;
    if (data.length) {
        cont = <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 50, bottom: 80, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 'auto',
                stacked: false,
                reverse: false
            }}
            enableGridX={true}
            enableGridY={true}
            enableSlices="x"
            curve="monotoneX"
            enableArea={false}
            axisTop={null}
            axisRight={null}
            pointSize={10}
            colors={['#178BD3']}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}

            sliceTooltip={({ slice }) => (
                <div className='rl_tooltip'>
                    {slice.points.map((point) => (
                        <div key={point.serieId} className='inner'>
                            <div className='label'>{point.data.x}:</div>
                            <div className='value'>{HelperService.formatPrice(point.data.y)}</div>
                        </div>
                    ))}
                </div>
            )}

        />

    }

    else {
        cont = 'Für die letzten 6 Monate sind leider noch keine Einnahmen vorhanden.'
    }

    return (
        <div className="chartbox">
            <div className="top">
                <span className="title">Einnahmen der letzten 6 Monate</span>
            </div>
            <div className="bottom">
                {cont}
            </div>
        </div>
    )
}

export default Chart