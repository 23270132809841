import ApiService from '../../services/api.service'
import { useState, useEffect } from 'react';
import HelperService from '../../services/helper.service';
import LoadingSpinner from '../../components/LoadingSpinner';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import StoreIcon from '@mui/icons-material/Store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import { Tooltip } from '@mui/material';
import AdminPie from '../../components/dashboard/admin/AdminPie';
import AdminChart from '../../components/dashboard/admin/AdminChart';
import TodayOrders from '../../components/dashboard/admin/TodayOrders';
import YesterdayOrders from '../../components/dashboard/admin/YesterdayOrders';

const AdminStartseite = () => {

  const [error, setError] = useState(null);
  if (error && error !== '404') {
    throw error;
  }

  const [loadReady, setLoadReady] = useState(false);
  const [response, setResponse] = useState();

  useEffect(() => {
    const API_URL = global.backendconfig.expressserver.url + '/admindashboard/getData';
    ApiService.getData(API_URL).then((response) => {
      if (typeof (response.apierror) !== 'undefined') {
        setError(response.apierror);
      }
      else {
        let dashboard = response.dashboard;
        setResponse(dashboard);
        setTimeout(() => {
          setLoadReady(true);
        }, 500);
      }
    }).catch((error) => {
      setError('NO_CONNECTION')
    });
  }, [])


  let provisiontoday = 0;

  let cont;
  cont = loadReady ?
    <>
      <span className="sectiontitle">Adminbereich</span>

      <div className='cpart flex'>
        <div className="third">
          <div className="dashbox">
            <div className="left">
              <span className="title">Angelegte Shops</span>
              <span className="counter">{response.shops.total}</span>
            </div>
            <div className="right">
              {response.shops.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{response.shops.today}</div></Tooltip> : <div></div>}
              <StoreIcon className='icon' />
            </div>
          </div>
        </div>
        <div className="third">
          <div className="dashbox">
            <div className="left">
              <span className="title">Angelegte User</span>
              <span className="counter">{response.users.total}</span>
            </div>
            <div className="right">
              {response.users.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{response.users.today}</div></Tooltip> : <div></div>}
              <PeopleAltIcon className='icon' />
            </div>
          </div>
        </div>
        <div className="third">
          <div className="dashbox highlighted">
            <div className="left">
              <span className="title">Erhaltene Provision</span>
              <span className="counter">{HelperService.formatPrice('0')}</span>
            </div>
            <div className="right">
              {provisiontoday !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{provisiontoday}</div></Tooltip> : <div></div>}
              <SavingsOutlinedIcon className='icon' />
            </div>
          </div>
        </div>
      </div>

      <div className='cpart flex'>
        <div className="third">
          <div className="dashbox">
            <div className="left">
              <span className="title">Transaktionen</span>
              <span className="counter">{response.orders.total}</span>
            </div>
            <div className="right">
              {response.orders.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{response.orders.today}</div></Tooltip> : <div></div>}
              <ShoppingCartOutlinedIcon className='icon' />
            </div>
          </div>
        </div>
        <div className="third">
          <div className="dashbox">
            <div className="left">
              <span className="title">Einnahmen</span>
              <span className="counter">{HelperService.formatPrice(response.earning.total)}</span>
            </div>
            <div className="right">
              {response.earning.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{HelperService.formatPrice(response.earning.today)}</div></Tooltip> : <div></div>}
              <EuroOutlinedIcon className='icon' />
            </div>
          </div>
        </div>
        <div className="third">
          <div className="dashbox">
            <div className="left">
              <span className="title">Verkaufte Produkte</span>
              <span className="counter">{response.sales.total}</span>
            </div>
            <div className="right">
              {response.sales.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{response.sales.today}</div></Tooltip> : <div></div>}
              <QrCodeOutlinedIcon className='icon' />
            </div>
          </div>
        </div>
      </div>

      <div className='cpart flex'>
        <div className='small-left'>
          <AdminPie data={response.sales.values} />
        </div>
        <div className='big-right'>
          <AdminChart data={response.earning.values} />
        </div>
      </div>

      <TodayOrders data={response.todayList} />
      <YesterdayOrders data={response.yesterdayList} />

    </>
    : <LoadingSpinner />;



  return (
    <div className='content'>
      {cont}
    </div>
  )

}

export default AdminStartseite

