import { ResponsivePie } from '@nivo/pie'
import HelperService from '../../../services/helper.service';

const AdminPie = ({ data }) => {

    let piedata = [];
    data.forEach((d) => {
        piedata.push({
            id: d.label,
            value: d.value,
            count: d.count
        })
    })
    piedata.sort((a, b) => a.value - b.value);

    var colorArray = HelperService.getChartColorsBlue();


    return (
        <div className='chartbox'>
            <div className="top">
                <span className="title">Einnahmen pro Shop</span>
            </div>
            <div className="bottom">
                <div className="featuredchart" style={{ height: 350 }}>
                    <ResponsivePie
                        data={piedata}
                        margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
                        innerRadius={0.3}
                        padAngle={2}
                        cornerRadius={0}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{ from: "color", modifiers: [["darker", 0.5]] }}
                        colors={colorArray}
                        enableArcLabels={false}
                        enableArcLinkLabels={false}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#000"
                        arcLinkLabelsThickness={2}
                        arcLabelsSkipAngle={10}
                        tooltip={point => {
                            return (
                                <div className='rl_tooltip'>
                                    <div className='inner'>
                                        <strong>{point.datum.id}</strong>
                                    </div>
                                    <div className='inner'>
                                        <div className='label'>Transaktionen:</div>
                                        <div className='value'>{point.datum.data.count}</div>
                                    </div>
                                    <div className='inner'>
                                        <div className='label'>Einnahmen:</div>
                                        <div className='value'>{HelperService.formatPrice(point.datum.value)}</div>
                                    </div>
                                </div>
                            )
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminPie
