import { DataGrid, deDE } from '@mui/x-data-grid';
import HelperService from '../../../services/helper.service';


const YesterdayOrders = ({ data }) => {

    if (data) {

        let listdata = [];
        let totalcount = 0;

        data.forEach((d) => {
            if (d.count > 0 || d.chargeCount > 0) {
                listdata.push(d)
                totalcount = totalcount + d.count + d.chargeCount
            }
        })

        const headCells = [
            {
                field: 'shop',
                flex: 1,
                minWidth: 160,
                headerName: 'Shop',
                filterable: false,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'count',
                flex: 1,
                minWidth: 100,
                headerName: 'Bestellungen',
                disableColumnMenu: true,
                sortable: false,
                headerAlign: 'center',
                align: 'center'
            },
            {
                field: 'chargeCount',
                flex: 1,
                minWidth: 100,
                headerName: 'Aufladungen',
                disableColumnMenu: true,
                sortable: false,
                headerAlign: 'center',
                align: 'center'
            },
            {
                field: 'value',
                flex: 1,
                minWidth: 100,
                headerName: 'Einnahmen',
                disableColumnMenu: true,
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                valueFormatter: params => HelperService.formatPrice(params?.value)
            }
        ];

        let table;
        if (listdata.length) {
            table =
                <>
                    <div className="sectiontitle">Transaktionen Gestern ({totalcount})</div>
                    <div className="listcontainer">
                        <div className='table nomargin'>
                            <DataGrid
                                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                rows={listdata}
                                columns={headCells}
                                disableRowSelectionOnClick={true}
                                columnHeaderHeight={38}
                                rowHeight={42}
                                hideFooter
                                disableColumnSelector
                                disableDensitySelector
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'orderStamp', sort: 'desc' }],
                                    }
                                }}
                            />
                        </div>
                    </div>
                </>
        }

        return table;

    }
}

export default YesterdayOrders;
