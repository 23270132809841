import { ResponsiveLine } from '@nivo/line'
import HelperService from '../../../services/helper.service';

const AdminChart = ({ data }) => {

    let chartdataentries = [];

    data.forEach((d) => {
        chartdataentries.push({
            x: d.label,
            y: d.value,
            z: d.count
        })
    });

    chartdataentries.sort((a, b) => a.x - b.x);

    let chartData = [
        {
            id: 'Einnahmen',
            data: chartdataentries
        }
    ]

    let cont;
    if (chartdataentries.length) {
        cont = <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 20, bottom: 80, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 'auto',
                stacked: false,
                reverse: false
            }}
            enableGridX={true}
            enableGridY={true}
            enableSlices="x"
            curve="monotoneX"
            enableArea={true}
            enablePoints={true}
            layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
            areaOpacity={0.5}
            areaBlendMode={'normal'}
            areaBaselineValue={0}
            legends={[]}
            isInteractive={true}
            debugMesh={false}
            tooltip={() => { }}
            debugSlices={false}
            enableCrosshair={true}
            crosshairType={'bottom-left'}
            role={''}
            defs={[]}
            fill={[]}
            axisTop={null}
            axisRight={null}
            lineWidth={3}
            pointSize={10}
            pointColor={{ "from": "color" }}
            enablePointLabel={false}
            colors={['#407b9e', '#aaaaaa']}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel={'yFormatted'}
            pointLabelYOffset={-12}
            useMesh={true}
            sliceTooltip={({ slice }) => (
                <div className='rl_tooltip'>
                    <div className='inner'>
                        <div className='label'>Transaktionen:</div>
                        <div className='value'>{slice.points[0].data.z}</div>
                    </div>
                    {slice.points.map((point) => (
                        <div key={point.serieId} className='inner'>
                            <div className='label'>{point.serieId}:</div>
                            <div className='value'>{HelperService.formatPrice(point.data.y)}</div>
                        </div>
                    ))}
                </div>
            )}
        />

    }

    else {
        cont = 'Es sind noch keine Daten vorhanden.'
    }

    return (
        <div className="chartbox">
            <div className="top">
                <span className="title">Einnahmen pro Jahr</span>
            </div>
            <div className="bottom">
                {cont}
            </div>
        </div>
    )
}

export default AdminChart