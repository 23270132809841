import { useRef } from "react";

const FileInput = ({ register, clearErrors, required, name, errors, onImageSelected, filename = false }) => {
  const inputRef = useRef();

  const emptyErrorMessage = 'Bitte auswählen';
  const req = required ? emptyErrorMessage : false;

  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      if (!filename) {

        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function (e) {
          onImageSelected(reader.result);
        };
      }
      else {
        onImageSelected(event.target.files[0]);
      }

    }
  };

  const onChooseImg = () => {
    if (required) {
      clearErrors(name)
    }
    inputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        {...register(name, {
          required: req
        })}
        accept="image/jpeg, image/png"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />
      <button type="button" className="btn" onClick={onChooseImg}>Bild auswählen</button>
      {errors[name] && <div className="error">{errors[name].message}</div>}
    </div>
  );
}

export default FileInput;
