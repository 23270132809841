import Chart from '../components/dashboard/Chart'
import LastOrders from '../components/dashboard/LastOrders';
import { useState, useEffect } from 'react';

import ApiService from '../services/api.service'
import LoadingSpinner from '../components/LoadingSpinner';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import HelperService from '../services/helper.service';
import Pie from '../components/dashboard/Pie';
import BatterySaverIcon from '@mui/icons-material/BatterySaver';
import { Tooltip } from '@mui/material';

const Startseite = () => {

  const [loadReady, setLoadReady] = useState(false);

  const [error, setError] = useState(null);
  if (error && error !== '404') {
    throw error;
  }

  const [response, setResponse] = useState();
  const [colors, setColors] = useState();
  const [charges, setCharges] = useState(false);

  useEffect(() => {
    const API_URL = global.backendconfig.expressserver.url + '/dashboard/getData';
    ApiService.getData(API_URL).then((response) => {
      if (typeof (response.apierror) !== 'undefined') {
        setError(response.apierror);
      }
      else {
        let dashboard = response.dashboard;
        setResponse(dashboard);

        if (dashboard.charges && dashboard.charges.total > 0) {
          setCharges(true);
        }
        var colorArray = HelperService.getChartColors();
        let colors = [];
        let count = 0;
        dashboard.sales.values.forEach((e) => {
          colors.push(colorArray[count])
          count++;
        });
        setColors(colors);

        setTimeout(() => {
          setLoadReady(true);
        }, 500);
      }
    }).catch((error) => {
      setError('NO_CONNECTION')
    });
  }, [])


  let cont;
  cont = loadReady ?
    <>
      <span className="sectiontitle">Dashboard</span>
      <div className='cpart flex'>
        <div className={charges ? "fourth" : "third"}>
          <div className="dashbox">
            <div className="left">
              <span className="title">Bestellungen</span>
              <span className="counter">{response.orders.total}</span>
            </div>
            <div className="right">
              {response.orders.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{response.orders.today}</div></Tooltip> : <div></div>}
              <ShoppingCartOutlinedIcon className='icon' />
            </div>
          </div>
        </div>
        {charges ?
          <div className="fourth">
            <div className="dashbox">
              <div className="left">
                <span className="title">Aufladungen</span>
                <span className="counter">{response.charges.total}</span>
              </div>
              <div className="right">
                {response.charges.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{response.charges.today}</div></Tooltip> : <div></div>}
                <BatterySaverIcon className='icon' />
              </div>
            </div>
          </div>
          : ''}
        <div className={charges ? "fourth" : "third"}>
          <div className="dashbox">
            <div className="left">
              <span className="title">Einnahmen</span>
              <span className="counter">{HelperService.formatPrice(response.earning.total)}</span>
            </div>
            <div className="right">
              {response.earning.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{HelperService.formatPrice(response.earning.today)}</div></Tooltip> : <div></div>}
              <EuroOutlinedIcon className='icon' />
            </div>
          </div>
        </div>
        <div className={charges ? "fourth" : "third"}>
          <div className="dashbox">
            <div className="left">
              <span className="title">Verkaufte Gutscheine</span>
              <span className="counter">{response.sales.total}</span>
            </div>
            <div className="right">
              {response.sales.today !== 0 ? <Tooltip title="Heute" arrow><div className="today"><KeyboardArrowUpOutlinedIcon />{response.sales.today}</div></Tooltip> : <div></div>}
              <QrCodeOutlinedIcon className='icon' />
            </div>
          </div>
        </div>
      </div>

      <div className='cpart flex'>
        <div className='small-left'>
          <Pie data={response.sales.values} colors={colors} />
        </div>
        <div className='big-right'>
          <Chart data={response.earning.values} />
        </div>
      </div>
      <LastOrders data={response.lastOrders} />
    </>
    : <LoadingSpinner />;



  return (
    <div className='content'>
      {cont}
    </div>
  )

}

export default Startseite

